<template>
  <div class="d-flex pa-3 justify-center">
    <v-card width="100%" elevation="0">
      <div class="pa-3">
        <!-- titulo y accion de cerrar componenete -->
        <v-row class="d-flex align-center justify-center">
          <h2>Imprimir historial de hospital</h2>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="btnGenerateHistorialHospital"
            class="ma-2 text-capitalize rounded-lg"
            >Imprimir</v-btn
          >
          <v-btn
            color="primary"
            icon
            @click="disableShowImprimirHistorialAtencionesdia"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </div>
    </v-card>
    <!-- Documento a imprimir -->
    <div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="900"
        filename="historialatencion"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="generateHistorialHospital"
      >
      
        <div slot="pdf-content">
          <div class="">
            <div class="headerA4Hospital">
              <strong class="main_titleA4">Historial de atenciones</strong>
              <div class="containt_logoA4">
                <img class="logoA4" :src="logoUpload" height="50px" />
              </div>
            </div>
          </div>
          <!-- Subtítulo-->
          <div class="subtitleA4Historial">
            <!-- Primera columna (datos receta) -->
            <div class="firstColumnSubtitle">
              <div class="id_containerA4">
                <strong>ID:</strong>
                <p class="idA4">5829abce-54c5-11ed-bdc3-0242ac120002</p>
              </div>
              <div class="date_containerA4">
                <strong>Fecha de emisión:</strong>
                <p class="fechaA4">{{ setDateShortString(today) }}</p>
              </div>
              <div class="amo_containerA4">
                <strong>Propietario(a):</strong>
                <div class="amoA4">{{ cliente.Amo }}</div>
              </div>
              <div class="run_containerA4">
                <strong>RUN:</strong>
                <div class="runA4">{{ cliente.Rut }}</div>
              </div>
            </div>
            <!-- Segunda columna (información empresa) -->
            <div class="secondColumnSubtitle">
              <div class="logoContainer">
                <div class="companyData">
                  <div>{{ clinica.Nombre }}</div>
                  <div>{{ clinica.Direccion }} , {{ clinica.Ciudad }}</div>
                  <div>{{ clinica.Fono }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Línea divisoria -->
          <hr />
          <!-- Datos paciente -->
          <div class="customersectionA4">
            <div class="first_row_mascota_dato">
              <div class="columnA4bigger">
                <strong>Paciente</strong>
                <p class="colInformation">{{ cliente.Nombre }}</p>
              </div>
              <div class="columnA4">
                <strong>Microchip</strong>
                <p class="colInformation">
                  {{ cliente.Microchip }}
                </p>
              </div>
              <div class="columnA4">
                <strong>Edad</strong>
                <p class="colInformation">{{ getAge(cliente.Nacimiento) }}</p>
              </div>
            </div>
            <div class="second_row_mascota_dato">
              <div class="columnA4bigger">
                <strong>Dirección</strong>
                <p class="addressA4 colInformation">
                  {{ cliente.Direccion }}
                </p>
              </div>
              <div class="columnA4 colInformation">
                <strong>Ciudad</strong>
                <p class="colInformation">
                  {{ cliente.Ciudad }}
                </p>
              </div>
              <div class="columnA4">
                <strong>Comuna</strong>
                <p class="colInformation">{{ cliente.Comuna }}</p>
              </div>
            </div>
          </div>
          <!-- Línea divisoria -->
          <hr />
          <!-- Contenido principal del certificado -->
          <!-- Descripción (todos los Certificados) -->
          <div class="descriptionContainer">
            <div
              v-for="controlhospital in controlesHospitalizados"
              :key="controlhospital.Id"
            >
              <div class="header_atencion">
                <div>
                  Atencion del
                  {{ setDateStringTimeZoneShort(controlhospital.Fecha) }} a las
                  {{ setTimeStringPrint(controlhospital.Hora) }}
                </div>
                <div class="mt-3 mr-3">
                  Atendido por: {{ controlhospital.Var2 }}
                </div>
             
              </div>
              <div class="d-flex">
                <h2>Control hospitalización</h2>
              </div>
              <div class="ma-0 pa-0 mt-3">
                <v-row class="d-flex">
                  <v-col class="d-flex">
                    <h2>T°:</h2>
                    <p class="ml-2 mt-1">
                      {{ controlhospital.Temp }}
                    </p>
                  </v-col>
                  <v-col class="d-flex">
                    <h2>Peso:</h2>
                    <p class="ml-2 mt-1">
                      {{ controlhospital.Var1 }}
                    </p>
                  </v-col>
                  <v-col class="d-flex">
                    <h2>
                      Fr.car:
                      <p class="ml-2 mt-1">
                        {{ controlhospital.Frecar }}
                      </p>
                    </h2>
                  </v-col>
                  <v-col class="d-flex">
                    <h2>Fr.res:</h2>
                    <p class="ml-2 mt-1">
                      {{ controlhospital.Freres }}
                    </p>
                  </v-col>
                  <v-col class="d-flex">
                    <h2>Pulso</h2>
                    <p class="ml-2 mt-1">
                      {{ controlhospital.Pressang }}
                    </p>
                  </v-col>
                </v-row>
                <div class="mt-1">
                  <v-row class="d-flex">
                    <v-col>
                      <strong>Observaciones:</strong>
                      <p class="mt-1">
                        {{ controlhospital.Observaciones }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
                <div class="mt-2">
                  <v-row class="d-flex">
                    <v-col>
                      <strong>Tratamiento:</strong>
                      <p class="mt-1">
                        {{ controlhospital.Tratamiento }}
                      </p>
                    </v-col>
                    <v-col>
                      <strong>Diagnostico:</strong>
                      <p>
                        {{ controlhospital.Diagnostico }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <hr class="divider_atencion" />
              <div class="html2pdf__page-break" />
            </div>
          </div>
          <!-- Línea divisoria -->
        </div>
      </vue-html2pdf>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "ImprimirHistorialHospital",
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      today: new Date().toISOString(),
    };
  },
  methods: {
    disableShowImprimirHistorialAtencionesdia() {
      this.$parent.activeShowPrinterHistorialAtencionesdia = false;
    },
    setDateShortString(date) {
      var dataSet = new Date(date.substring(0, 10) + "T00:00:00");
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " de " + year;
    },
    btnGenerateHistorialHospital() {
      this.$refs.generateHistorialHospital.generatePdf();
    },
    setDateString(date) {
      var dataSet = new Date(date);
      dataSet = this.setDateNow(dataSet);
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year + " a las " + h + ":" + m;
    },
    setDateStringPrint(date) {
      var dataSet = new Date(date);
      dataSet = dataSet;
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return (
        day +
        " de " +
        montTex +
        " del " +
        year +
        " a las " +
        h +
        ":" +
        m +
        " horas"
      );
    },
    setTimeStringPrint(date) {
      var dataSet = new Date(date);
      dataSet = dataSet;
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return h + ":" + m + " horas";
    },
    setDateStringShortPrint(date) {
      var dataSet = new Date(date);
      dataSet = dataSet;
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year;
    },
    setDateNow(date) {
      var currentDateObj = date;
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 180000;
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      return newDateObj;
    },
    getAge(dateString) {
      var now = new Date();
      var today = new Date(now.getYear(), now.getMonth(), now.getDate());

      var yearNow = now.getYear();
      var monthNow = now.getMonth();
      var dateNow = now.getDate();

      var dob = new Date(
        dateString.substring(0, 4),
        dateString.substring(5, 7) - 1,
        dateString.substring(8, 10)
      );

      var yearDob = dob.getYear();
      var monthDob = dob.getMonth();
      var dateDob = dob.getDate();
      var age = {};
      var ageString = "";
      var yearString = "";
      var monthString = "";
      var dayString = "";

      let yearAge = yearNow - yearDob;

      if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
      else {
        yearAge--;
        var monthAge = 12 + monthNow - monthDob;
      }

      if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
      else {
        monthAge--;
        var dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }

      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge,
      };

      if (age.years > 1) yearString = " años";
      else yearString = " año";
      if (age.months > 1) monthString = " meses";
      else monthString = " mes";
      if (age.days > 1) dayString = " días";
      else dayString = " día";

      if (age.years > 0 && age.months > 0 && age.days > 0)
        ageString =
          age.years +
          yearString +
          ", " +
          age.months +
          monthString +
          ", y " +
          age.days +
          dayString;
      else if (age.years == 0 && age.months == 0 && age.days > 0)
        ageString = "solo " + age.days + dayString;
      else if (age.years > 0 && age.months == 0 && age.days == 0)
        ageString = age.years + yearString + " Feliz cumpleaños!!";
      else if (age.years > 0 && age.months > 0 && age.days == 0)
        ageString = age.years + yearString + " y " + age.months + monthString;
      else if (age.years == 0 && age.months > 0 && age.days > 0)
        ageString =
          age.months + monthString + " y " + age.days + dayString + ".";
      else if (age.years > 0 && age.months == 0 && age.days > 0)
        ageString = age.years + yearString + " y " + age.days + dayString + ".";
      else if (age.years == 0 && age.months > 0 && age.days == 0)
        ageString = age.months + monthString + " .";
      else ageString = "Oops! No es posible calcular!";

      return ageString;
    },
    setDateStringTimeZoneShort(date) {
      var dataSet = new Date(date.substring(0, 10) + "T00:00:00");

      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year;
    },
  },
  computed: {
    ...mapState("medical-module", [
      "produs",
      "memos",
      "atenciondia",
      "atenciondiasByCliente",
      "controlesHospitalizados",
    ]),
    ...mapState("customers-module", ["cliente"]),
    ...mapState("auth-module", ["logoUpload", "clinica"]),
    filterProdus() {
      return this.produs.filter((item) => item.Ubicacion == "VACUNAS");
    },
    setData() {
      return this.descriptionCertificado.replace(/(?:\r\n|\r|\n)/g, "<br/>");
    },
  },
  created() {},
};
</script>
<style>
/* Contenido receta a4 */
strong {
  font-weight: 800;
  letter-spacing: 2px;
}

/* Encabezado */
.headerA4Hospital {
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 10px;
  height: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.headerLogo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.main_titleA4 {
  color: #0d47a1;
  font-weight: bolder;
  letter-spacing: 2px;
  font-size: 16px;
}
/* Subtítulo */
.subtitleA4Historial {
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 15px;
}
.id_containerA4 {
  display: flex;
}
.idA4 {
  margin-left: 3px;
}
.fechaA4 {
  margin-left: 3px;
}
.date_containerA4 {
  display: flex;
  margin-top: 3px;
}
.amo_containerA4 {
  display: flex;
  margin-top: 10px;
}
.amoA4 {
  margin: 0px;
  margin-left: 3px;
}
.run_containerA4 {
  display: flex;
}
.runA4 {
  margin: 0px;
  margin-left: 3px;
}
.header_atencion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 5px;
}
.divider_atencion {
  margin-top: 10px;
  margin-bottom: 10px;
}
/* Fin encabezado */

/* Datos paciente */
.customersectionA4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  height: 10%;
}
.addressA4 {
  padding-right: 10px;
}
.first_row_mascota_dato {
  display: flex;
}
.second_row_mascota_dato {
  display: flex;
  padding-top: 20px;
}
.columnA4 {
  width: 40%;
}
.columnA4bigger {
  width: 46%;
}
/* Fin datos paciente */

/* Contenido principal certificado */
.cuerpoFormulario {
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 10px;
}
/* Variación Certificado de Estadía en Hotel */
/* Ingreso/salida */
.ingresoSalidaContainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-bottom: 20px;
}
.checkinContainer {
  display: flex;
}
.checkoutContainer {
  display: flex;
  margin-top: 3px;
}
.checkin {
  margin-left: 3px;
}
.checkout {
  margin-left: 3px;
}
/* Fin ingreso/salida */
/* Datos de planificación */
.sectionDataPlanning {
  display: flex;
  margin-bottom: 10px;
  height: 20%;
}
.column {
  width: 35%;
  display: flex;
  flex-direction: column;
}
.colInformation {
  margin-top: 3px;
}
.containt_headerA4Hosptial{
  display: flex;
   justify-content: space-between;
}
/* Fin descripción */
/* Fin variación Estadía en Hotel */

/* Variación Certificado Radiológico */
.certificadoDataSmaller {
  height: 15%;
}
.certificadoData {
  height: 27%;
  padding-bottom: 10px;
  margin-top: 3px;
}
/* Fin variación Certificado Radiológico */

/* Variación Certificado Vacuna */
.vaccineContainer {
  display: flex;
  flex-direction: column;
  height: 30%;
}
.vaccine {
  margin-top: 3px;
}
/* Fin variación Certificado Vacuna */

/* Fin datos de planificación */
/* Descripción */
.descriptionContainer {
  margin-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  height: 38%;
}
.description {
  margin-top: 3px;
}
/* Datos médico tratante */
.dr_data {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 10%;
}
.icon {
  margin-right: 10px;
}
/* Fin datos médico tratante */
/* Línea divisoria (horizontal) */
hr {
  margin: auto;
  border: 0.2px solid #e9ebe9;
  width: 95%;
}
/* Pie (footer)  */
.footer-verifyA4 {
  display: flex;
  width: 867px;
  padding-right: 50px;
  padding-left: 50px;
  padding-top: 20px;
  height: 10%;
  align-content: center;
}
.first_column_footer {
  width: 60%;
}
.verify_document {
  margin-top: 10px;
  margin-right: 100px;
  background: #f7f7f7;
  padding: 5px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.msg_qr {
  padding-top: 20px;
}
.second_column_footer {
  width: 40%;
}
.qr_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.qr {
  margin-top: 10px;
  width: 80px;
}
/* Fin pie */
/* Fin contenido A4 */
</style>