<template>
  <div>
    <div class="d-flex mb-10 my-8 flex-wrap sticky-card py-8" width="100%">
      <v-btn icon rounded @click="$router.go(-1)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dense v-bind="attrs" v-on="on">
              <v-icon size="28">mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Volver</span>
        </v-tooltip>
      </v-btn>
      <div class="d-flex customerDataContainer">
        <h3 class="mt-2 primary--text" v-if="cliente.Nombre">
          {{ cliente.Fichan }} | {{ cliente.Nombre }}
        </h3>
      </div>
      <v-spacer></v-spacer>
      <div class="mainTitleContainer">
        <h1 class="justify-center mr-5">Hospital</h1>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-btn
          v-if="cliente.Fichan"
          color="primary"
          class="ma-1 rounded-lg text-capitalize"
          @click="btnSelectCuentaHospital"
          >Cargar insumos a cuenta</v-btn
        >
        <v-btn
          v-if="cliente.Fichan"
          color="primary"
          outlined
          class="ma-1 rounded-lg text-capitalize"
          @click="btnGoToProcedimientos"
          >Procedimientos</v-btn
        >
      </div>
    </div>
    <!-- lista de hospitalizados -->
    <v-col cols="12">
      <v-card
        width="100%"
        class="pa-3 mb-5 rounded-xl"
        :loading="activeLoadingSearchHospitalizados"
      >
        <v-card-title class="mt-4 ml-10">
          <h2>Hospitalizados</h2>
        </v-card-title>
        <div class="pa-3">
          <v-data-table
            :headers="headersHospitalizados"
            :items="hospitalizados"
            :search="searchHospitalizado"
            :items-per-page="5"
            class="mt-2"
            dense
            :footer-props="{
              'items-per-page-text': 'Filas por página',
            }"
          >
            <template v-slot:item.action="{ item }">
              <div class="actions pa-1">
                <v-btn
                  icon
                  color="primary"
                  @click="btnSelectHospitalizado(item)"
                  class="rounded-lg text-capitalize"
                  ><v-icon>mdi-check-circle-outline</v-icon>
                </v-btn>
              </div>
            </template>

            <template v-slot:no-data>
              <v-alert :value="true"> No hay resultados disponibles </v-alert>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-col>
    <div v-if="cliente.Nombre">
      <!-- ingreso y paciente -->
      <div class="pa-3 ma-3">
        <!-- ingreso y paciente -->
        <v-row>
          <!-- central ingreso -->
          <v-col
            class="d-flex flex-column"
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <h2 class="ma-2 mb-5" ref="centralViewControl">Control</h2>
            <!-- nuevo paciente -->
            <!-- <div class="my-3 d-flex">
          <h3 class="mt-2 mx-2 black--text">Seleccione masctoa</h3>
          <v-select label="Nueva hospitalizacion" outlined dense></v-select>
        </div> -->
            <!-- profesional -->
            <div class="ma-1">
              <p>Profesional</p>
              <div v-if="licen.Var37 == 1">
                <v-autocomplete
                  v-model="doctor.Nombre"
                  :items="doctors"
                  item-text="Nombre"
                  item-value="Nombre"
                  outlined
                  dense
                  label="Profesional"
                  class="ma-2"
                ></v-autocomplete>
              </div>
              <div v-else>
                <v-text-field
                  class="ma-2"
                  v-model="doctor.Nombre"
                  outlined
                  label="Profesional"
                  dense
                  readonly
                ></v-text-field>
              </div>
            </div>
            <!-- seleccion de fecha y hora -->
            <div class="">
              <!-- fecha -->
              <v-menu
                v-model="menuFechaingreso"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Fecha de ingreso"
                    prepend-icon="mdi-calendar"
                    class="ma-2"
                    outlined
                    v-model="fechaIngresoHospitalizacion"
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaIngresoHospitalizacion"
                  @input="menuFechaingreso = false"
                ></v-date-picker>
              </v-menu>
              <!-- hora de ingreso -->
              <v-menu
                ref="menuStart"
                v-model="menuStartTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="timeStart"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="timeStart"
                    outlined
                    dense
                    class="ma-2"
                    label="Hora de ingreso"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuStartTime"
                  format="24hr"
                  v-model="timeStart"
                  full-width
                  @click:minute="$refs.menuStart.save(timeStart)"
                ></v-time-picker>
              </v-menu>
            </div>
          </v-col>
          <v-divider
            vertical
            class="my-4 d-flex d-sm-none d-xs-none d-md-flex d-lg-flex d-xl-flex"
          ></v-divider>
          <!--  datos del paciente -->
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <h2 class="ma-2 mb-5">Paciente</h2>
            <div>
              <div
                class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
              >
                <v-text-field
                  v-model="cliente.Nombre"
                  outlined
                  readonly
                  label="Nombre"
                  dense
                  class="ma-2"
                ></v-text-field>
                <v-text-field
                  outlined
                  label="N° de ficha"
                  v-model="cliente.Fichan"
                  readonly
                  dense
                  class="ma-2"
                >
                </v-text-field>
              </div>
              <div
                class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
              >
                <v-text-field
                  outlined
                  dense
                  readonly
                  label="Edad"
                  :value="getAge(cliente.Nacimiento)"
                  class="ma-2"
                >
                </v-text-field>
                <v-text-field
                  outlined
                  label="Fecha de nacimiento"
                  :value="setDateNacimiento(cliente.Nacimiento)"
                  dense
                  readonly
                  class="ma-2"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="cliente.Sexo"
                  label="Género"
                  class="ma-2"
                ></v-text-field>
              </div>
              <div
                class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
              >
                <v-text-field
                  outlined
                  v-model="cliente.Raza"
                  label="Raza"
                  readonly
                  dense
                  class="ma-2"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  v-model="cliente.Esp"
                  label="Especie"
                  readonly
                  class="ma-2"
                ></v-text-field>
              </div>
              <div
                class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
              >
                <v-text-field
                  outlined
                  dense
                  v-model="cliente.Microchip"
                  label="N° de chip"
                  readonly
                  class="ma-2"
                >
                </v-text-field>
                <v-text-field
                  outlined
                  readonly
                  :value="cliente.Color"
                  dense
                  label="Color"
                  class="ma-2"
                ></v-text-field>
              </div>
              <!-- <div class="d-flex">
              <div class="mt-2 mr-2">Crear nuevo</div>
              <v-btn outlined color="primary" class="ma-1">Procedimiento</v-btn>
              <v-btn outlined color="primary" class="ma-1">Insumo</v-btn>
              <v-btn outlined color="primary" class="ma-1">Examen</v-btn>
              <v-btn outlined color="primary" class="ma-1">Control</v-btn>
            </div> -->
            </div>
          </v-col>
        </v-row>
        <!-- Nuevo controles de atención -->
        <div
          class="d-flex my-8 flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
        >
          <h2 ref="formhospital" class="black_h3 my-3 ma-2">Control de hospitalización</h2>
          <v-spacer></v-spacer>
          <div
            class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
          >
            <v-btn
              @click="activeShowDialogoDarAlta = true"
              color="primary"
              class="mx-2 text-capitalize rounded-lg"
              >Dar de alta</v-btn
            >
            <v-btn
              @click="btnActiveHistorialControles"
              color="primary"
              outlined
              class="btnViewRecord mx-2 text-capitalize rounded-lg"
              >Ver historial de controles</v-btn
            >
          </div>
        </div>
        <!-- Diálogo al pinchar "dar de alta" a la mascota -->
        <v-row>
          <v-col cols="12" xs="12" sm="11" md="5" lg="2" xl="2">
            <v-dialog
              v-model="activeShowDialogoDarAlta"
              max-width="600px"
              class="ma-3"
              persistent
            >
              <v-card class="rounded-xl pa-3 d-flex flex-column justify-center">
                <h2 class="d-flex pa-3">
                  ¿Seguro que quiere dar de alta a la <br />
                  mascota {{ cliente.Nombre }}?
                </h2>
                <v-card-text class="pa-3">
                  <p>
                    Esta acción no se puede deshacer, confirme cuando esté
                    completamente seguro(a).
                  </p>
                </v-card-text>
                <v-card-action class="pa-3 d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="ma-2 text-capitalize rounded-lg"
                    @click="btnCreateDarAlta"
                    :loading="activeLoadingCreateDarAlta"
                    >Confirmar, dar de alta</v-btn
                  >
                  <v-btn
                    color="primary"
                    outlined
                    class="ma-2 text-capitalize rounded-lg"
                    @click="activeShowDialogoDarAlta = false"
                    >Cancelar</v-btn
                  >
                </v-card-action>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <!-- formulario e historial de atencion -->
        <v-row  class="ma-2 d-flex flex-column mb-5">
          <!-- triage de hospitalizacion -->
          <div
            class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
          >
            <v-text-field
              outlined
              dense
              v-model="hospitalizado.Temp"
              type="number"
              label="T°"
              class="ma-2"
            >
            </v-text-field>
            <v-text-field
              outlined
              dense
              v-model="hospitalizado.Var1"
              type="number"
              label="Peso"
              class="ma-2"
            >
            </v-text-field>
            <v-text-field
              v-model="hospitalizado.Pressang"
              outlined
              dense
              type="number"
              label="Pulso"
              class="ma-2"
            >
            </v-text-field>
            <v-text-field
              outlined
              dense
              v-model="hospitalizado.Frecar"
              type="number"
              label="Fr. Card."
              class="ma-2"
            >
            </v-text-field>
            <v-text-field
              outlined
              dense
              label="Fr. Resp."
              v-model="hospitalizado.Freres"
              type="number"
              class="ma-2"
            >
            </v-text-field>
          </div>
          <!-- central -->
          <div>
            <div>
              <v-textarea
                name="input-7-1"
                outlined
                v-model="hospitalizado.Observaciones"
                label="Observaciones"
                auto-grow
                class="ma-2"
              ></v-textarea>
            </div>
            <div>
              <v-textarea
                name="input-7-1"
                outlined
                v-model="hospitalizado.Tratamiento"
                label="Tratamiento"
                auto-grow
                class="ma-2"
              ></v-textarea>
            </div>
          </div>
          <!-- botones de acción -->
          <div class="d-flex justify-end mx-1">
            <!-- botón crear nuevo o actualizar control de hospitalización -->
            <v-btn
              color="primary"
              v-if="!selectControlHospitalizado"
              class="mx-2 text-capitalize rounded-lg"
              :loading="activeUploadingCreateControlHospitalizado"
              @click="btnCreateControlHospitalizado"
              >Grabar control</v-btn
            >
            <div v-if="selectControlHospitalizado" class="d-flex justify-end">
              <v-btn
                color="primary"
                class="ma-1 text-capitalize rounded-lg"
                :loading="activeUploadingUpdateControlHospitalizado"
                @click="btnUpdateControlHospitalizado"
                >Actualizar control</v-btn
              >

              <v-btn
                v-if="selectControlHospitalizado"
                color="primary"
                outlined
                class="ma-1 text-capitalize rounded-lg"
                >Eliminar</v-btn
              >
              <v-btn
                color="primary"
                outlined
                class="ma-1 text-capitalize rounded-lg"
                @click="btnNewControl"
                >Limpiar para nuevo control</v-btn
              >
            </div>
          </div>
          <!-- historial de atenciones -->
          <div class="my-10" ref="cardhisto">
            <v-card
              elevation="1"
              v-if="activeShowControlesHospitalizacion"
              class="pa-3 mb-5 rounded-xl"
            >
              <v-card-title class="mt-4 ml-10">
                <h2 ref="viewHistorialHospitalizacion">
                  Historial de controles de la hospitalización
                </h2>
                   <v-btn
                      color="primary"
                      icon
                      class="mx-2"
                      @click="activeShowPrinterHistorialHospital = true"
                    >
                      <v-icon>mdi-printer-outline</v-icon>
                    </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  color="primary"
                  @click="activeShowControlesHospitalizacion = false"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-card-title>
                    
                <div
                  class="d-flex pa-3 justify-center"
                  v-if="activeShowPrinterHistorialHospital"
                >
                  <ImprimirHistorialHospital />
                </div>
              <div>
                <div class="pa-2">
                  <v-data-table
                    :headers="headersControlesHospitalizados"
                    :items="controlesHospitalizados"
                    :items-per-page="5"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    item-key="Id"
                    show-expand
                    class="ma-3"
                    dense
                    :footer-props="{
                      'items-per-page-text': 'Filas por página',
                    }"
                  >
                    <template v-slot:item.action="{ item }">
                      <div class="actions pa-1">
                        <v-btn
                          icon
                          color="primary"
                          @click="btnSelectEditControlHospitalizado(item.Id)"
                          class="rounded-lg text-capitalize"
                          ><v-icon>mdi-clipboard-edit-outline</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <template v-slot:item.Hora="{ item }">
                      <div class="actions pa-1">
                        {{ setShortTime(item.Hora) }}
                      </div>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <div class="pa-2">
                          <div class="d-flex flex-column">
                            <div class="my-2">
                              <strong>Observaciones </strong
                              >{{ item.Observaciones }}
                            </div>
                            <div class="my-2">
                              <strong>Tratamiento </strong
                              >{{ item.Tratamiento }}
                            </div>
                          </div>
                        </div>
                      </td>
                    </template>
                    <template v-slot:no-data>
                      <v-alert :value="true">
                        No hay resultados disponibles
                      </v-alert>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </v-card>
            <!-- Notificación creación de control hospitalizado -->
            <v-snackbar
              color="primary"
              v-model="successCreateControlHospitalizado"
              class="text-center"
            >
              Control de hospitalización creado con éxito
            </v-snackbar>
          </div>
          <!-- Notificación actualización de control hospitalizado -->
          <v-snackbar
            color="primary"
            v-model="successUpdateControlHospitalizado"
            class="text-center"
          >
            Control de hospitalización actualizado con éxito
          </v-snackbar>
        </v-row>
      </div>
      <!-- Procedimientos  -->
      <div
        v-if="activeShowProcedimientos"
        ref="viewProcedimientos"
        class="mt-8"
      >
        <v-card class="pa-3 mb-5 rounded-xl ma-2">
          <v-card-title class="mt-4 ml-10">
            <h2 class="ma-2 mb-5">Procedimientos</h2>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              icon
              @click="activeShowProcedimientos = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-row class="mb-15 d-flex flex-wrap">
            <v-col class="firstColumnProcess" cols="12" sm="12" xs="12" md="8">
              <v-textarea
                outlined
                v-model="procedimiento.Procedimiento"
                label="Detalle del procedimiento"
              ></v-textarea>
            </v-col>
            <v-col class="secondColumnProcess" cols="12" xs="12" sm="12" md="">
              <v-menu
                v-model="menuFechaProcedimiento"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    class="mx-2"
                    outlined
                    v-model="fechaProcedimiento"
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaProcedimiento"
                  @input="menuFechaProcedimiento = false"
                ></v-date-picker>
              </v-menu>
              <!-- hora de ingreso -->
              <v-menu
                ref="menuProcedimiento"
                v-model="menuStartTimeProcedimiento"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="horaProcedimiento"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="horaProcedimiento"
                    outlined
                    dense
                    class="mx-2 mt-2"
                    label="Hora"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menuStartTimeProcedimiento"
                  format="24hr"
                  v-model="horaProcedimiento"
                  full-width
                  @click:minute="
                    $refs.menuProcedimiento.save(horaProcedimiento)
                  "
                ></v-time-picker>
              </v-menu>
              <div class="d-flex justify-end mx-2">
                <v-checkbox
                  v-model="procedimientoRealizado"
                  label="Procedimiento Realizado"
                ></v-checkbox>
              </div>
              <div class="d-flex justify-end">
                <v-btn
                  color="primary"
                  class="text-capitalize rounded-lg mr-2"
                  @click="btnCreateProcediminiento"
                  :loading="activeUploadingCreateProcedimiento"
                  >Grabar procedimiento</v-btn
                >
              </div>
            </v-col>
          </v-row>
          <v-divider class="ma-2 mb-10"></v-divider>
          <h3 class="black_h3 mt-4 ml-10">Historial de Procedimientos</h3>

          <div class="pa-3">
            <v-data-table
              :headers="headersProcedimientos"
              :items="procedimientos"
              :search="searchProcedimiento"
              :single-expand="singleExpandProcedimiento"
              :expanded.sync="expandedProcedimiento"
              item-key="Id"
              show-expand
              :items-per-page="5"
              class="mt-2"
              dense
              :footer-props="{
                'items-per-page-text': 'Filas por página',
              }"
            >
              <template v-slot:item.action="{ item }">
                <div class="actions pa-1">
                  <v-btn icon color="primary" class="rounded-lg text-capitalize"
                    ><v-icon>mdi-check-circle-outline</v-icon>
                  </v-btn>
                </div>
              </template>
              <template v-slot:item.Estado="{ item }">
                <div class="actions pa-1">
                  <v-chip
                    v-if="(item.Estado = '0')"
                    color="primary"
                    outlined
                    small
                    >Pendiente</v-chip
                  >
                  <v-chip v-else color="primary" outlined small
                    >Realizado</v-chip
                  >
                </div>
              </template>
              <template v-slot:item.Hora="{ item }">
                <div class="">
                  {{ setShortTime(item.Hora) }}
                </div>
              </template>
              <template v-slot:item.action="{ item }">
                <div class="">
                  <v-btn icon><v-icon>mdi-delete-outline</v-icon></v-btn>
                </div>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <div class="pa-2">
                    <div class="d-flex flex-column">
                      <div class="my-2">
                        <p>{{ item.Procedimiento }}</p>
                      </div>
                    </div>
                  </div>
                </td>
              </template>
              <template v-slot:no-data>
                <v-alert :value="true"> No hay resultados disponibles </v-alert>
              </template>
            </v-data-table>
          </div>
        </v-card>
        <!-- Notificación creación de procedimiento -->
        <v-snackbar
          color="primary"
          v-model="successCreateProcedimiento"
          class="text-center"
        >
          Procedimiento creado con éxito
        </v-snackbar>
      </div>
    </div>
    <!-- seleccion -->
    <div v-else class="d-flex justify-center pa-3 ma-3">
      <div class="d-flex flex-column">
        <v-avatar tile size="300" class="d-flex justify-center">
          <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
        </v-avatar>
        <div class="d-flex align-center flex-column ml-8">
          <h3 class="mt-5 ml-3">Seleccione una mascota</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ImprimirHistorialHospital from "../Components/ImprimirHistorialHospital.vue";

export default {
  components: {
    ImprimirHistorialHospital,
  },
  data() {
    return {
      activeDev: true,
      searchHospitalizado: "",
      headersHospitalizados: [
        { text: "N° Ficha", value: "Fichan" },
        { text: "Mascota", value: "Nombre" },
        { text: "Raza", value: "Raza" },
        { text: "Propietario", value: "Amo" },
        { text: "Seleccionar", value: "action" },
      ],
      headersProcedimientos: [
        {
          text: "Fecha",
          value: "Fecha",
        },
        { text: "Hora", value: "Hora" },
        { text: "Estado", value: "Estado" },
        { text: "Eliminar", value: "action" },
        { text: "Ver más", value: "data-table-expand" },
      ],
      headersControlesHospitalizados: [
        { text: "Fecha", value: "Fecha" },
        { text: "Hora", value: "Hora" },
        { text: "T°", value: "Temp" },
        { text: "F.res", value: "Freres" },
        { text: "F.car", value: "Frecar" },
        { text: "Pulso", value: "Pressang" },
        { text: "Peso", value: "Var1" },
        { text: "Médico", value: "Var2" },
        { text: "Ver o editar", value: "action" },
        { text: "Ver más", value: "data-table-expand" },
      ],
      menuStartTimeProcedimiento: false,
      horaProcedimiento: "",
      menuStartTime: false,
      menuFechaProcedimiento: false,
      fechaProcedimiento: "",
      menuFechaingreso: false,
      timeStart: "",
      searchProcedimiento: "",
      fechaIngresoHospitalizacion: "",
      expanded: [],
      activeShowControlesHospitalizacion: false,
      singleExpand: false,
      expandedProcedimiento: [],
      singleExpandProcedimiento: false,
      procedimiento: {
        Fecha: "",
        Hora: "",
        Procedimiento: "",
        Estado: "",
        Ficha: 0,
        Estadoalta: "0",
      },
      activeShowPrinterHistorialHospital: false,
      procedimientoRealizado: false,
      activeUploadingCreateProcedimiento: false,
      activeUploadingCreateControlHospitalizado: false,
      selectControlHospitalizado: "",
      activeUploadingUpdateControlHospitalizado: false,
      activeShowProcedimientos: false,
      activeShowDialogoDarAlta: false,
      activeLoadingCreateDarAlta: false,
    };
  },
  watch: {
    selectFichaHospitalizado: async function (val) {
      await this.getClienteByFichan(val);
      this.getProcedimientosByFicha({ ficha: val });
      this.getControlesHospitalizados({ ficha: val });
    },
    successCreateProcedimiento: function (val) {
      if (val) {
        this.procedimientoRealizado = false;
        this.procedimiento.Procedimiento = "";
        this.fechaProcedimiento = "";
        this.horaProcedimiento = "";
        this.activeUploadingCreateProcedimiento = false;
      }
    },
    successCreateControlHospitalizado: function (val) {
      if (val) {
        this.activeUploadingCreateControlHospitalizado = false;
        this.setControlHospitalizadoByFicha({
          controlHospitalizado: {
            Fecha: "",
            Ficha: 0,
            Rn: "0",
            Hora: "",
            Temp: "",
            Frecar: "",
            Freres: "",
            Pressang: "",
            Observaciones: "",
            Var1: "",
            Var2: "",
            Var3: "",
            Fecha2: "",
            Tratamiento: "",
            Diagnostico: "",
          },
        });
      }
    },
    successUpdateControlHospitalizado: function (val) {
      if (val) {
        this.activeUploadingUpdateControlHospitalizado = false;
      }
    },
    successCreateAltaHospitalizado: function (val) {
      if (val) {
        this.activeLoadingCreateDarAlta = false;
        this.activeShowDialogoDarAlta = false;
        this.$router.push("/medical/records");
      }
    },
  },
  methods: {
    ...mapActions("medical-module", [
      "getProcedimientosByFicha",
      "getControlesHospitalizados",
      "postProcedimientoByFicha",
      "postControlHospitalizadoByFicha",
      "setControlHospitalizadoByFicha",
      "postUpdateControlHospitalizadoByFicha",
      "actionSelectFichaCuentaHospital",
      "postDarAltaHospitalizadoByFicha",
    ]),
    ...mapActions("customers-module", ["getClienteByFichan"]),
    getAge(dateString) {
      var now = new Date();
      var today = new Date(now.getYear(), now.getMonth(), now.getDate());

      var yearNow = now.getYear();
      var monthNow = now.getMonth();
      var dateNow = now.getDate();

      var dob = new Date(
        dateString.substring(0, 4),
        dateString.substring(5, 7) - 1,
        dateString.substring(8, 10)
      );

      var yearDob = dob.getYear();
      var monthDob = dob.getMonth();
      var dateDob = dob.getDate();
      var age = {};
      var ageString = "";
      var yearString = "";
      var monthString = "";
      var dayString = "";

      let yearAge = yearNow - yearDob;

      if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
      else {
        yearAge--;
        var monthAge = 12 + monthNow - monthDob;
      }

      if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
      else {
        monthAge--;
        var dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }

      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge,
      };

      if (age.years > 1) yearString = " años";
      else yearString = " año";
      if (age.months > 1) monthString = " meses";
      else monthString = " mes";
      if (age.days > 1) dayString = " días";
      else dayString = " día";

      if (age.years > 0 && age.months > 0 && age.days > 0)
        ageString =
          age.years +
          yearString +
          ", " +
          age.months +
          monthString +
          ", y " +
          age.days +
          dayString;
      else if (age.years == 0 && age.months == 0 && age.days > 0)
        ageString = "solo " + age.days + dayString;
      else if (age.years > 0 && age.months == 0 && age.days == 0)
        ageString = age.years + yearString + " Feliz cumpleaños!!";
      else if (age.years > 0 && age.months > 0 && age.days == 0)
        ageString = age.years + yearString + " y " + age.months + monthString;
      else if (age.years == 0 && age.months > 0 && age.days > 0)
        ageString =
          age.months + monthString + " y " + age.days + dayString + ".";
      else if (age.years > 0 && age.months == 0 && age.days > 0)
        ageString = age.years + yearString + " y " + age.days + dayString + ".";
      else if (age.years == 0 && age.months > 0 && age.days == 0)
        ageString = age.months + monthString + " .";
      else ageString = "Oops! No es posible calcular!";

      return ageString;
    },
    setDateNacimiento(nacimiento) {
      return nacimiento.substring(0, 10);
    },
    async btnSelectHospitalizado(hospitalizado) {
      this.selectFichaHospitalizado = hospitalizado.Fichan;
      await this.getClienteByFichan(hospitalizado.Fichan);
      this.getProcedimientosByFicha({ ficha: hospitalizado.Fichan });
      this.getControlesHospitalizados({ ficha: hospitalizado.Fichan });
      setTimeout(() => {
        var element = this.$refs["centralViewControl"];
        var top = element.offsetTop;
        window.scrollTo(0, top - 101);
      }, 300);
    },
    setShortTime(time) {
      return time.substring(11, 19);
    },
    btnCreateProcediminiento() {
      this.activeUploadingCreateProcedimiento = true;
      this.procedimiento.Fecha = this.fechaProcedimiento.substring(0, 10);
      this.procedimiento.Hora =
        this.fechaProcedimiento.substring(0, 10) + " " + this.horaProcedimiento;
      if (this.procedimientoRealizado) {
        this.procedimiento.Estado = "1";
      } else {
        this.procedimiento.Estado = "0";
      }
      this.procedimiento.Ficha = this.cliente.Fichan;
      this.postProcedimientoByFicha({ procedimiento: this.procedimiento });
    },
    btnCreateControlHospitalizado() {
      if (!this.fechaIngresoHospitalizacion) {
        alert("seleccione fecha de ingreso");
        return;
      }
      if (!this.timeStart) {
        alert("seleccione hora de ingreso");
        return;
      }
      this.activeUploadingCreateControlHospitalizado = true;
      this.hospitalizado.Fecha = this.fechaIngresoHospitalizacion.substring(
        0,
        10
      );
      this.hospitalizado.Hora =
        this.fechaIngresoHospitalizacion.substring(0, 10) +
        " " +
        this.timeStart;
      this.hospitalizado.Ficha = this.cliente.Fichan;
      this.hospitalizado.Sucursal = this.user.Sucursal;
      this.hospitalizado.Var2 = this.doctor.Nombredoc;
      let set_hospitalizado = this.hospitalizado;
      set_hospitalizado.Var2 = this.doctor.Nombre;
      this.postControlHospitalizadoByFicha({
        controlHospitalizado: set_hospitalizado,
        sucursal: this.user.Sucursal,
      });
    },
    btnSelectEditControlHospitalizado(id) {
       var element = this.$refs["formhospital"];
        var top = element.offsetTop;
        window.scrollTo(0, top - 140);
      this.selectControlHospitalizado = id;
      this.fechaIngresoHospitalizacion = this.hospitalizado.Fecha;
      this.timeStart = this.hospitalizado.Hora.substring(11, 19);
      this.setControlHospitalizadoByFicha({
        controlHospitalizado: this.controlesHospitalizados.find(
          (item) => item.Id == id
        ),
      });
    },

    btnNewControl(id) {
      this.selectControlHospitalizado = "";

      this.setControlHospitalizadoByFicha({
        controlHospitalizado: {
          Fecha: "",
          Ficha: 0,
          Rn: "0",
          Hora: "",
          Temp: "",
          Frecar: "",
          Freres: "",
          Pressang: "",
          Observaciones: "",
          Var1: "",
          Var2: "",
          Var3: "",
          Fecha2: "",
          Tratamiento: "",
          Diagnostico: "",
          Sucursal: "",
        },
      });
    },
    btnUpdateControlHospitalizado() {
      this.activeUploadingUpdateControlHospitalizado = true;
      this.postUpdateControlHospitalizadoByFicha({
        controlHospitalizado: this.hospitalizado,
      });
    
       
 
    },
    btnSelectCuentaHospital() {
      this.actionSelectFichaCuentaHospital(this.cliente.Fichan);
      setTimeout(() => {
        this.$router.push("/medical/sales");
      }, 200);
    },
    btnActiveHistorialControles() {
      this.activeShowControlesHospitalizacion = true;
      setTimeout(() => {
        var element = this.$refs["cardhisto"];
        var top = element.offsetTop;
        window.scrollTo(0, top - 101);
      }, 300);
    },
    async setLoadOpenPage() {
      await this.getClienteByFichan(this.cliente.Fichan);
      this.getProcedimientosByFicha({ ficha: this.cliente.Fichan });
      this.getControlesHospitalizados({ ficha: this.cliente.Fichan });
      setTimeout(() => {
        var element = this.$refs["centralViewControl"];
        var top = element.offsetTop;
        window.scrollTo(0, top - 101);
      }, 300);
    },
    btnGoToProcedimientos() {
      this.activeShowProcedimientos = true;
      setTimeout(() => {
        var element = this.$refs["viewProcedimientos"];
        var top = element.offsetTop;
        window.scrollTo(0, top - 101);
      }, 300);
    },
    btnCreateDarAlta() {
      this.activeLoadingCreateDarAlta = true;
      this.postDarAltaHospitalizadoByFicha({ ficha: this.cliente.Fichan });
    },
  },
  computed: {
    ...mapState("customers-module", [
      "customers",
      "selectFichaClienteId",
      "cliente",
      "clientes",
      "loadingClientes",
    ]),
    ...mapState("auth-module", ["user", "licen"]),
    ...mapState("medical-module", [
      "medicalCareVisits",
      "doctors",
      "doctor",
      "motivovisitas",
      "atenciondias",
      "diagnostico",
      "fames",
      "produs",
      "servisHistory",
      "atenciondiasByCliente",
      "atenciondia",
      "checklists",
      "checklist",
      "directories",
      "successCreateDiagnostico",
      "successCreateChecklist",
      "successCreateService",
      "recetas",
      "receta",
      "successCreateReceta",
      "successSentEmail",
      "hospitalizados",
      "controlesHospitalizados",
      "procedimientos",
      "successCreateProcedimiento",
      "hospitalizado",
      "successCreateControlHospitalizado",
      "successUpdateControlHospitalizado",
      "successCreateAltaHospitalizado",
      "activeLoadingSearchHospitalizados",
    ]),
  },
  created() {
    this.setLoadOpenPage();
  },
};
</script>
<style>
.sticky-card {
  position: sticky;
  top: 100px;
  z-index: 1;
  background: #fff;
}

/* RWD Título principal */
@media only screen and (max-width: 700px) {
  .customerDataContainer {
    width: 80%;
  }
  .mainTitleContainer {
    padding-left: 20px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 940px) {
  .btnViewRecord {
    margin-top: 5px;
  }
}

/* Eliminar espacio bajo input "hora" de procedimientos */
.v-text-field__details {
  display: none;
}

/* Quitar margin por defedcto de input checkbox "procedimiento realizado" */
.v-input--selection-controls.v-input {
  margin-top: 2px !important;
}

/* RWD tarjeta Procedimientos */
@media only screen and (min-width: 600px) {
  .firstColumnProcess {
    margin-left: 10px;
  }
  .secondColumnProcess {
    margin-right: 5px;
  }
}
@media only screen and (max-width: 600px) {
  .secondColumnProcess {
    margin-right: 5px;
  }
}
</style>
